import dayjs from 'dayjs'

export const formatDate = ({
  date,
  showTime = true,
  delimiter = '',
  defaultReturnValue = '—',
}: {
  date: Date | string | number | null | undefined
  showTime?: boolean
  delimiter?: string
  defaultReturnValue?: string
}): string => {
  if (!date) {
    return defaultReturnValue
  }

  try {
    const dayjsDate = typeof date === 'number' ? dayjs(date) : dayjs(new Date(date))
    if (dayjsDate.isValid()) {
      const dateString = dayjsDate.format('MM/DD/YYYY')
      if (!showTime) {
        return dateString
      }
      const time = dayjsDate.format('hh:mm A')
      return `${dateString}${delimiter} ${time}`
    }
    return defaultReturnValue
  } catch (error) {
    return defaultReturnValue
  }
}

export const formatDateIntl = (date: string) => {
  try {
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(date))
  } catch (e) {
    return ''
  }
}
