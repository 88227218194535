import { captureException } from 'sentry'
import { CalendlyParams } from '../types/CalendlyParams'

export class Calendly {
  private static baseUrl =
    'https://calendly.com/returnpro/returnpro-for-enterprise?hide_gdpr_banner=1'

  static setup() {
    try {
      if (typeof window === 'undefined') {
        throw new Error('window is not defined.')
      }

      if (this.isCalendlyDefined) {
        throw new Error('Calendly is already defined.')
      }

      this.insertScriptInHead()
      this.insertCssInHead()
    } catch (error) {
      if (!(error instanceof Error) || error.message !== 'Calendly is already defined.') {
        captureException(error)
      }

      console.error(error)
    }
  }

  static initPopupWidget(params: CalendlyParams) {
    try {
      if (!this.isCalendlyDefined) {
        throw new Error('Calendly is not defined.')
      }
      const url = new URL(this.baseUrl)
      this.appendParams(url, params)

      window.Calendly.initPopupWidget({ url: url.href })
    } catch (error) {
      if (!(error instanceof Error) || error.message !== 'Calendly is not defined.') {
        captureException(error)
      }

      console.error(error)
    }
  }

  private static appendParams(url: URL, params: CalendlyParams) {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value)
    })
  }

  private static insertScriptInHead() {
    const script = document.createElement('script')
    script.setAttribute('defer', '')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(script)
  }

  private static insertCssInHead() {
    const cssLink = document.createElement('link')
    cssLink.setAttribute('rel', 'stylesheet')
    cssLink.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css')
    document.head.appendChild(cssLink)
  }

  private static get isCalendlyDefined(): boolean {
    return !!window.Calendly
  }
}
