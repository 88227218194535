export const REQUIRED_FIELD = 'Field is required'
export const EMAILS_DO_NOT_MATCH = 'Emails do not match'
export const PASSWORDS_DO_NOT_MATCH = 'Passwords do not match'
export const CONFIRM_PASSWORD = 'Confirm password'
export const INVALID_PHONE_NUMBER = 'Invalid phone number'
export const INVALID_EMAIL_ADDRESS = 'Invalid email address'
export const INVALID_ZIP_CODE = 'Invalid ZIP/Postal code'
export const FROM_MUST_BE_LESS_THAN_TO = '"From" must be less than "To"'
export const TO_MUST_BE_GREATER_THAN_FROM = '"To" must be greater than "From"'
export const PASSWORD_INVALID =
  'Password must contain 8+ characters, lowercase and uppercase letters, and at least 1 digit'
