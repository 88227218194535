import * as Sentry from '@sentry/core'
import { isAxiosError } from 'axios'

export function captureException(error: unknown) {
  Sentry.captureException(error)
}

export function captureAPIError(error: unknown) {
  if (!isAxiosError(error) || (error.status || 0) > 499) {
    Sentry.captureException(error)
  }
}
