import React from 'react'
import styled from '@emotion/styled'
import { Icon, Link } from '@r1/ui-kit'

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.blue[500]};
`

export const KnowledgeBaseLink = () => (
  <StyledLink href="https://returnpro.zendesk.com/hc/en-us" target="_blank">
    <StyledIcon type="learn" />
    <span>Knowledge base</span>
  </StyledLink>
)
